// Importation des styles et des modules
import '../scss/main.scss';


// import initProjets from './projets.js';
import initIndex, { animateWords } from './index.js'; // Import de initIndex et animateWords

// import initBr from './br.js';


import { initBootstrap } from "./bootstrap.js"; // Chargement de l'initialisation de Bootstrap
import { bottom, flip, viewport } from '@popperjs/core'; // Modules Popper.js
import barba from "@barba/core"; // Barba.js pour les transitions de page
// import barbaPrefetch from "@barba/prefetch";
import { gsap } from "gsap"; // GreenSock Animation Platform pour les animations
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';
import { dpkCursor } from 'dpk_cursor';
import { SplitText } from 'gsap/SplitText.js';
import initSplit from './mySplitText.js';
// import "../../node_modules/bootstrap-icons/font/bootstrap-icons.scss";

// Exportation de initBootstrap pour utilisation dans d'autres fichiers
export { initBootstrap, bottom, viewport };


// import { dpkCursor } from 'dpk_cursor';




gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, SplitText);







// Fonction pour initialiser les animations et les écouteurs d'événements sur la page
function initPage() {

  const toggleMenu = document.getElementById('toggleMenu');
  const menu = document.getElementById('menu');
  const pageContent = document.querySelector('.page-move');
  const currentPath = window.location.pathname.split("/").pop();

  // Gestion des clics sur les liens du menu
  if (toggleMenu && menu && pageContent) {
    toggleMenu.addEventListener('click', toggleMenuHandler);

    const menuLinks = document.querySelectorAll('.menu-links a');
    menuLinks.forEach(link => {
      link.addEventListener('click', function (event) {
        event.preventDefault(); // Empêche le comportement par défaut du lien
        const href = this.getAttribute('href').split('/').pop();
        // Vérifier si le lien cliqué mène à la page active
        if (currentPath === href) {
          closeMenuToRight(); // Fermer le menu
        } else {
          closeMenuToLeft(this.getAttribute('href')).then(() => {
          });
        }
      });
    });
  }

  /////////////////////////////
  // TEXTE CHANGE DE COULEUR //
  /////////////////////////////

  const split = new SplitText(".text-wrapper p", {
    type: "words"
  });



  // FIN INIT PAGE
}




///////////////
// DARK MODE //
///////////////

function applyDarkMode() {
  let isToggled = localStorage.getItem('darkMode') === 'true';

  // Sélectionner les éléments après chaque transition
  const bgElements = document.querySelectorAll('.bg-dark, .bg-light');
  // console.log('bgElements:', bgElements);

  const textElements = document.querySelectorAll('.text-dark, .text-light');
  const NightDayIcon = document.getElementById('NightDayIcon');
  const colorModeIcon = document.getElementById('colorMode');
  const menu = document.getElementById('menu');
  const introElement = document.getElementById('intro');
  const menuLiens = document.querySelectorAll('#menu a');

  if (isToggled) {
    bgElements.forEach(element => {
      // if (element !== menu && (introElement && !element.contains(introElement))) {
      element.classList.remove('bg-light');
      element.classList.add('bg-dark');
      // }
    });

    textElements.forEach(element => {
      // if (element !== menu && (introElement && !element.contains(introElement))) {
      element.classList.remove('text-dark');
      element.classList.add('text-light');
      // }
    });

    menu.classList.remove('bg-dark');
    menu.classList.add('bg-light');
    menu.classList.remove('text-light');
    menu.classList.add('text-dark');

    menuLiens.forEach(link => {
      link.classList.remove('text-light');
      link.classList.add('text-dark');
    });

    colorModeIcon.classList.add('bi-toggle-on');
    colorModeIcon.classList.remove('bi-toggle-off');
    NightDayIcon.classList.add('bi-moon-stars');
    NightDayIcon.classList.remove('bi-brightness-high');
  } else {
    bgElements.forEach(element => {
      // if (element !== menu && (introElement && !element.contains(introElement))) {
      element.classList.remove('bg-dark');
      element.classList.add('bg-light');
      // }
    });

    textElements.forEach(element => {
      // if (element !== menu && (introElement && !element.contains(introElement))) {
      element.classList.remove('text-light');
      element.classList.add('text-dark');
      // }
    });

    menu.classList.remove('bg-light');
    menu.classList.add('bg-dark');
    menu.classList.remove('text-dark');
    menu.classList.add('text-light');

    menuLiens.forEach(link => {
      link.classList.remove('text-dark');
      link.classList.add('text-light');
    });

    colorModeIcon.classList.add('bi-toggle-off');
    colorModeIcon.classList.remove('bi-toggle-on');
    NightDayIcon.classList.add('bi-brightness-high');
    NightDayIcon.classList.remove('bi-moon-stars');
  }
}



function toggleDarkMode() {
  let isToggled = localStorage.getItem('darkMode') === 'true';
  isToggled = !isToggled;
  localStorage.setItem('darkMode', isToggled);
  applyDarkMode();
}



function basculerModeEnFonctionDeLHeure() {
  const heureActuelle = new Date().getHours();
  let isToggled = localStorage.getItem('darkMode') === 'true';

  if (heureActuelle >= 19 || heureActuelle < 7) {
    if (!isToggled) {
      isToggled = true;
      localStorage.setItem('darkMode', isToggled);
      applyDarkMode();
    }
  } else {
    if (isToggled) {
      isToggled = false;
      localStorage.setItem('darkMode', isToggled);
      applyDarkMode();
    }
  }
}


////////////////
// INIT BARBA //
////////////////

document.addEventListener('DOMContentLoaded', () => {

  barba.init({

    views: [
      {
        namespace: 'index',
        beforeEnter(data) {
          gsap.fromTo(data.next.container, { x: 100 }, { x: 0, opacity: 1, duration: 1 });
          initIndex();
          console.log('namespace index');
        },
      },
      {
        namespace: 'agenda',
        beforeEnter(data) {
          gsap.fromTo(data.next.container, { x: 100 }, { x: 0, duration: 1 });
        }
      },
      {
        namespace: 'contact',
        beforeEnter(data) {
          gsap.fromTo(data.next.container, { x: 100 }, { x: 0, duration: 1 });
        }
      },
      {
        namespace: 'bc',
        beforeEnter(data) {
          gsap.fromTo(data.next.container, { x: 100 }, { x: 0, duration: 1 });
          initSplit();
        }
      },
      {
        namespace: 'bm',
        beforeEnter(data) {
          gsap.fromTo(data.next.container, { x: 100 }, { x: 0, duration: 1 });
          initSplit();
        }
      },
      {
        namespace: 'br',
        beforeEnter(data) {
          gsap.fromTo(data.next.container, { x: 100 }, { x: 0, duration: 1 });
          initSplit();
        }
      },
      {
        namespace: 'gf',
        beforeEnter(data) {
          gsap.fromTo(data.next.container, { x: 100 }, { x: 0, duration: 1 });
          initSplit();
        }
      }
    ],
  });

  barba.hooks.beforeEnter((data) => {
    applyDarkMode();
    initPage();

    if (!detectMobile()) {
      initDpk();
    } else {
      destroyDpk();
    }
    
  });

  barba.hooks.enter((data) => {
    if (data.next.namespace === 'index') {
      initIndex();
    }
    gsap.fromTo(data.next.container, { x: 100 }, { x: 0, duration: 1 });
  });

  barba.hooks.beforeLeave(() => {
    window.scrollTo(0,0);
    applyDarkMode();
    destroyDpk();
  });

  applyDarkMode();
});





// Fonction pour ouvrir le menu
function openMenu() {
  const menu = document.getElementById('menu');
  const pageContent = document.querySelector('.page-move');
  gsap.set(menu, { x: '100%' });
  gsap.to(menu, { x: 0, duration: 1 });
  gsap.to(pageContent, { x: -200, duration: 1 });
  document.body.style.overflow = 'hidden';
  toggleMenu.classList.add('active');
  menu.classList.add('active');
}

// Fonction pour fermer le menu vers la droite
function closeMenuToRight() {
  const menu = document.getElementById('menu');
  const pageContent = document.querySelector('.page-move');
  gsap.to(menu, { x: '100%', duration: 1 });
  gsap.to(pageContent, { x: 0, duration: 1 });

  // Supprimer la classe active du menu
  document.body.style.overflow = '';
  toggleMenu.classList.remove('active');
  menu.classList.remove('active');
}

// Gestionnaire de clic pour le bouton toggle du menu
function toggleMenuHandler() {
  const menu = document.getElementById('menu');
  toggleMenu.classList.toggle('active');
  menu.classList.toggle('active');
  if (menu.classList.contains('active')) {
    openMenu();
  } else {
    closeMenuToRight(); // Appeler closeMenuToRight ici
  }
}

// Gestionnaire de clic pour fermer le menu vers la gauche
async function closeMenuToLeft(href) {
  const menu = document.getElementById('menu');
  const pageContent = document.querySelector('.page-move');
  const timeline = gsap.timeline();
  timeline.to(menu, { x: '-100%', duration: 1 }); // Animation de fermeture du menu vers la gauche
  timeline.to(pageContent, { x: 0, duration: 1 }); // Animation de retour du contenu de la page à sa position initiale
  // Supprimer la classe active du menu
  document.body.style.overflow = '';
  toggleMenu.classList.remove('active');
  menu.classList.remove('active');
}

///////////
// INTRO //
///////////
let playIntro = true;

function introIndex() {
  return new Promise((resolve) => {
    const introElement = document.querySelector('.intro');
    const navElement = document.querySelector('nav');
    const menuElement = document.getElementById('menu');
    const introTextElement1 = document.querySelector('.intro-text-1');
    const introTextElement2 = document.querySelector('.intro-text-2');
    const photo = document.querySelector('.photo');

    document.body.classList.add('no-scroll');

    if (introElement && introTextElement1 && introTextElement2 && navElement && menuElement && photo) {
      const introTimeline = gsap.timeline({
        onComplete: () => {
          document.body.classList.remove('no-scroll');
          resolve();
        }
      });

      if (playIntro) {
        introTimeline
          .set(navElement, { opacity: 0 })         // Masquer le nav au début de l'animation
          .set(menuElement, { opacity: 0 })        // Masquer le menu au début de l'animation
          .set(introTextElement1, { display: 'block' }) // S'assurer que l'intro est visible avant l'animation
          .set(introTextElement2, { display: 'block' }) // S'assurer que l'intro est visible avant l'animation
          .fromTo(introTextElement1, { opacity: 0 }, { opacity: 1, duration: 1 })  // Faire apparaître le texte sur 1 seconde
          .fromTo(photo, { opacity: 0 }, { opacity: 1, duration: 1 }, "-=0.5")  // Faire apparaître la photo sur 1 seconde, avec un décalage pour chevaucher l'apparition du texte
          .fromTo(introTextElement2, { x: '100%', opacity: 0 }, { x: '0%', opacity: 1, duration: 1 }, "-=0.5")  // Faire apparaître le texte sur 1 seconde, avec un décalage pour chevaucher l'apparition de la photo
          .to([introTextElement1, introTextElement2, photo], { opacity: 0, duration: 1, delay: 1 })  // Faire disparaître tous les éléments sur 1 seconde après un délai
          .to(introElement, { xPercent: -100, duration: 1 })   // Faire remonter l'intro sur 1 seconde
          .set(introElement, { display: 'none' })             // Masquer l'intro après l'animation
          .to(navElement, { opacity: 1, duration: 0 })         // Réafficher le nav à la fin de l'animation
          .fromTo(menuElement, { opacity: 0 }, { opacity: 1, duration: 1 }); // Faire apparaître le menu à la fin de l'animation
      } else {
        gsap.set(introElement, { display: 'none', visibility: 'hidden' });
        resolve();
      }
    } else {
      document.body.classList.remove('no-scroll');
      resolve();
    }
  });
}

///////////////
// FIN INTRO //
///////////////


// Initialisation de dpkCursor
let customCursor;

function initDpk() {
  customCursor = new dpkCursor({ ease: 0.25 });
}

function destroyDpk() {
  if (customCursor) {
    customCursor.destroy();
    customCursor = null;
  }
}

// Détection du mobile
function detectMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}



document.addEventListener('DOMContentLoaded', () => {

  // Vérifier si l'icône de mode couleur existe et ajouter un écouteur d'événements
  const colorModeIcon = document.getElementById('colorMode');
  if (colorModeIcon) {
    colorModeIcon.addEventListener('click', toggleDarkMode);
  }

  basculerModeEnFonctionDeLHeure();

  applyDarkMode();

  // Vérifier si l'appareil n'est pas mobile et initialiser Dpk si nécessaire
  if (!detectMobile()) {
    initDpk();
  }

  // Initialiser la page
  initPage();
  initIndex();
  // Exécuter l'introduction de l'index si nécessaire
  introIndex();
  if (playIntro) {
    introIndex();
  }


});




