import '../scss/main.scss';
// import "../../node_modules/bootstrap-icons/font/bootstrap-icons.scss";

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";
import { bottom, flip, viewport } from '@popperjs/core';
// Export initBootstrap for use in other files
export { initBootstrap, bottom, viewport };

import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { SplitText } from 'gsap/SplitText.js';

gsap.registerPlugin(ScrollTrigger, SplitText,);

// FONCTION CHANGE COULEUR TEXTE



// FONCTION
export default function initSplit() {
    
    // TEXTE CHANGE DE COULEUR
    const split = new SplitText(".text-wrapper p", {
        type: "words"
    });

    const tl = gsap
    .timeline({
      scrollTrigger: {
        // trigger: "#scrollText",
        start: "top top",
        end: "+=100%",
        pin: true,
        // pinSpacing: false,
        scrub: 0.75,
        // markers: true
      }
    })

  // Définir l'animation pour changer l'opacité et la couleur des caractères
  tl.fromTo(
    split.words,
    {
      color: "var(--light)",
      opacity: 0.2 // Opacité initiale
    },
    {
      opacity: 1, // Opacité finale
      color: "var(--dark)", // Couleur finale
      stagger: 0.1
    },
    0
  );


}




////////////////
// FIN EXPORT //
////////////////
