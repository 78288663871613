// Importation des styles et des modules
import '../scss/main.scss';


// import initProjets from './projets.js';
// import initIndex, { animateWords } from './index.js'; // Import de initIndex et animateWords

import { initBootstrap } from "./bootstrap.js"; // Chargement de l'initialisation de Bootstrap
import { bottom, flip, viewport } from '@popperjs/core'; // Modules Popper.js
import { gsap } from "gsap"; // GreenSock Animation Platform pour les animations
import { ScrollSmoother } from 'gsap/ScrollSmoother.js';
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

// import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
// import { ScrollToPlugin } from 'gsap/ScrollToPlugin.js';
import { dpkCursor } from 'dpk_cursor';


// Exportation de initBootstrap pour utilisation dans d'autres fichiers
// export { initBootstrap, bottom, viewport };






/////////////////////
// REGISTER PLUGIN //
/////////////////////
gsap.registerPlugin(ScrollTrigger, ScrollSmoother);






///////////////////
// SMOOTH SCROLL //
///////////////////
let smoother = ScrollSmoother.create({
  wrapper: '#smooth-wrapper',
  content: '#smooth-content',
  smooth: 2,
  smoothTouch: 0.5,
  effects: true,
})


////////////////
// DPK CURSOR //
////////////////
const customCursor = new dpkCursor({ ease: 0.25 });

///////////////////
// DETECT MOBILE //
///////////////////

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  // document.write("mobile");
  customCursor.destroy();
  // ScrollSmoother.destroy(); DOESN'T EXIST

} else {
  // document.write("not mobile");
}


//////////
// MENU //
//////////


// // Gérer l'ajout de la classe active sur les indicateurs du menu
const indicateurs = document.querySelectorAll(".indicator");
const sections = document.querySelectorAll("section");

const reinitialiserIndicateurActif = () => {
  const indicateurActif = document.querySelector(".active");
  if (indicateurActif) {
    indicateurActif.classList.remove("active");
  }
};

const surSectionEntreDansViewport = (section) => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          reinitialiserIndicateurActif();
          const element = entry.target;
          const indicateur = document.querySelector(`a[href='#${element.id}']`);
          if (indicateur) {
            indicateur.classList.add("active");
          }
        }
      });
    },
    {
      root: null,
      rootMargin: "0px",
      threshold: 0.6,  // Déclenche lorsque 60% de la section est visible
    }
  );
  observer.observe(section);
};

sections.forEach(surSectionEntreDansViewport);

// Ajouter un comportement de défilement fluide lors du clic sur un indicateur
indicateurs.forEach((indicateur) => {
  indicateur.addEventListener("click", function (event) {
    event.preventDefault();
    smoother.scrollTo(this.getAttribute("href"), true, "top top");
    reinitialiserIndicateurActif();
    this.classList.add("active");
  });
});
//

let intro = document.querySelector(".intro");
intro.addEventListener("click", e => {
  smoother.scrollTo("#intro", true, "top top")
});

let bc = document.querySelector(".bc");
bc.addEventListener("click", e => {
  smoother.scrollTo("#bc", true, "top top")
});

let bm = document.querySelector(".bm");
bm.addEventListener("click", e => {
  smoother.scrollTo("#bm", true, "top top")
});

let gf = document.querySelector(".gf");
gf.addEventListener("click", e => {
  smoother.scrollTo("#gf", true, "top top")
});

let br = document.querySelector(".br");
br.addEventListener("click", e => {
  smoother.scrollTo("#br", true, "top top")
});

let agenda = document.querySelector(".agenda");
agenda.addEventListener("click", e => {
  smoother.scrollTo("#agenda", true, "top top")
});

let contact = document.querySelector(".contact");
contact.addEventListener("click", e => {
  smoother.scrollTo("#contact", true, "top top")
});

//////////////////
// PLAYER AUDIO //
//////////////////

window.addEventListener('load', function() {
    const audio = document.getElementById('audio');
    const playPauseBtn = document.getElementById('playPauseBtn');
    const prevBtn = document.getElementById('prevBtn');
    const nextBtn = document.getElementById('nextBtn');
    const playIcon = document.getElementById('playIcon');
    const pauseIcon = document.getElementById('pauseIcon');
    const currentTimeSpan = document.getElementById('currentTime');
    const totalTimeSpan = document.getElementById('totalTime');
    const currentTrackTitle = document.getElementById('currentTrackTitle');
    const playlist = document.getElementById('playlist');
    const tracks = Array.from(playlist.querySelectorAll('li'));
    let currentTrackIndex = 0;
    let shuffledTracks = shuffleArray(tracks);
  
    loadTrack(currentTrackIndex);
  
    playPauseBtn.addEventListener('click', function () {
        if (audio.paused) {
            audio.play();
            togglePlayPauseIcons(true);
        } else {
            audio.pause();
            togglePlayPauseIcons(false);
        }
    });
  
    nextBtn.addEventListener('click', function () {
        nextTrack();
    });
  
    prevBtn.addEventListener('click', function () {
        prevTrack();
    });
  
    audio.addEventListener('timeupdate', function () {
        currentTimeSpan.textContent = formatTime(audio.currentTime);
        if (!isNaN(audio.duration)) {
            totalTimeSpan.textContent = formatTime(audio.duration);
        }
    });
  
    audio.addEventListener('ended', function () {
        nextTrack();
    });
  
    // Function to shuffle an array
    function shuffleArray(array) {
        let shuffled = array.slice();
        for (let i = shuffled.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
        }
        return shuffled;
    }
  
    function loadTrack(index) {
        const selectedTrack = shuffledTracks[index];
        audio.src = selectedTrack.getAttribute('data-src');
        currentTrackTitle.textContent = selectedTrack.textContent;
        togglePlayPauseIcons(false);
    }
    
  
    function nextTrack() {
        currentTrackIndex = (currentTrackIndex + 1) % shuffledTracks.length;
        loadTrack(currentTrackIndex);
        audio.play();
        togglePlayPauseIcons(true);
    }
  
    function prevTrack() {
        currentTrackIndex = (currentTrackIndex - 1 + shuffledTracks.length) % shuffledTracks.length;
        loadTrack(currentTrackIndex);
        audio.play();
        togglePlayPauseIcons(true);
    }
  
    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60);
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    }
  
    function togglePlayPauseIcons(isPlaying) {
        if (isPlaying) {
            playIcon.style.display = 'none';
            pauseIcon.style.display = 'inline';
        } else {
            playIcon.style.display = 'inline';
            pauseIcon.style.display = 'none';
        }
    }
  });
  
  



////////////////
// DPK CURSOR //
////////////////

// Initialisation de dpkCursor
// let customCursor;

// customCursor = new dpkCursor({ ease: 0.25 });



// function initDpk() {
//   customCursor = new dpkCursor({ ease: 0.25 });
// }

// function destroyDpk() {
//   if (customCursor) {
//     customCursor.destroy();
//     customCursor = null;

//   }
// }

///////////////////
// DETECT MOBILE //
///////////////////

// if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
//     // document.write("mobile");
//     customCursor.destroy();
//     // ScrollSmoother.destroy(); DOESN'T EXIST
  
//   } else {
//     // document.write("not mobile");
//   }



// Fonction pour initialiser les animations et les écouteurs d'événements sur la page
// function initPage() {


// }




///////////////
// DARK MODE //
///////////////

// function applyDarkMode() {
//   let isToggled = localStorage.getItem('darkMode') === 'true';

//   // Sélectionner les éléments après chaque transition
//   const bgElements = document.querySelectorAll('.bg-dark, .bg-light');
//   // console.log('bgElements:', bgElements);

//   const textElements = document.querySelectorAll('.text-dark, .text-light');
//   const NightDayIcon = document.getElementById('NightDayIcon');
//   const colorModeIcon = document.getElementById('colorMode');
//   const menu = document.getElementById('menu');
//   const introElement = document.getElementById('intro');
//   const menuLiens = document.querySelectorAll('#menu a');

//   if (isToggled) {
//     bgElements.forEach(element => {
//       // if (element !== menu && (introElement && !element.contains(introElement))) {
//       element.classList.remove('bg-light');
//       element.classList.add('bg-dark');
//       // }
//     });

//     textElements.forEach(element => {
//       // if (element !== menu && (introElement && !element.contains(introElement))) {
//       element.classList.remove('text-dark');
//       element.classList.add('text-light');
//       // }
//     });

//     menu.classList.remove('bg-dark');
//     menu.classList.add('bg-light');
//     menu.classList.remove('text-light');
//     menu.classList.add('text-dark');

//     menuLiens.forEach(link => {
//       link.classList.remove('text-light');
//       link.classList.add('text-dark');
//     });

//     colorModeIcon.classList.add('bi-toggle-on');
//     colorModeIcon.classList.remove('bi-toggle-off');
//     NightDayIcon.classList.add('bi-moon-stars');
//     NightDayIcon.classList.remove('bi-brightness-high');
//   } else {
//     bgElements.forEach(element => {
//       // if (element !== menu && (introElement && !element.contains(introElement))) {
//       element.classList.remove('bg-dark');
//       element.classList.add('bg-light');
//       // }
//     });

//     textElements.forEach(element => {
//       // if (element !== menu && (introElement && !element.contains(introElement))) {
//       element.classList.remove('text-light');
//       element.classList.add('text-dark');
//       // }
//     });

//     menu.classList.remove('bg-light');
//     menu.classList.add('bg-dark');
//     menu.classList.remove('text-dark');
//     menu.classList.add('text-light');

//     menuLiens.forEach(link => {
//       link.classList.remove('text-dark');
//       link.classList.add('text-light');
//     });

//     colorModeIcon.classList.add('bi-toggle-off');
//     colorModeIcon.classList.remove('bi-toggle-on');
//     NightDayIcon.classList.add('bi-brightness-high');
//     NightDayIcon.classList.remove('bi-moon-stars');
//   }
// }



// function toggleDarkMode() {
//   let isToggled = localStorage.getItem('darkMode') === 'true';
//   isToggled = !isToggled;
//   localStorage.setItem('darkMode', isToggled);
//   applyDarkMode();
// }



// function basculerModeEnFonctionDeLHeure() {
//   const heureActuelle = new Date().getHours();
//   let isToggled = localStorage.getItem('darkMode') === 'true';

//   if (heureActuelle >= 19 || heureActuelle < 7) {
//     if (!isToggled) {
//       isToggled = true;
//       localStorage.setItem('darkMode', isToggled);
//       applyDarkMode();
//     }
//   } else {
//     if (isToggled) {
//       isToggled = false;
//       localStorage.setItem('darkMode', isToggled);
//       applyDarkMode();
//     }
//   }
// }






// // Détection du mobile
// function detectMobile() {
//   return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
// }



// document.addEventListener('DOMContentLoaded', () => {

//   // Vérifier si l'icône de mode couleur existe et ajouter un écouteur d'événements
//   const colorModeIcon = document.getElementById('colorMode');
//   if (colorModeIcon) {
//     colorModeIcon.addEventListener('click', toggleDarkMode);
//   }

//   basculerModeEnFonctionDeLHeure();

//   applyDarkMode();

//   // Vérifier si l'appareil n'est pas mobile et initialiser Dpk si nécessaire
  // if (!detectMobile()) {
  //   initDpk();
  //   // destroyDpk()
  // }

//   // Initialiser la page
//   initPage();
//   initIndex();
//   // Exécuter l'introduction de l'index si nécessaire
//   // introIndex();
//   // if (playIntro) {
//   //   introIndex();
//   // }


// });




